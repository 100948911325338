import React, { useState, lazy, Suspense, useEffect } from "react";
import "./News.scss";
import ArticalBox from "../../components/ArticalBox/ArticalBox.js";
import { useIdContext } from "../../shared/IdProvider.js";
import { Link } from "react-router-dom";
import { asideBoxesNews, asideBoxesNews2, options } from "../../shared/shared.js";
import { urlTitle2 } from "../../shared/utillity.js";
import { useGlobalLocation } from "../../shared/LocationContext.js";

export default function News() {
  window.scrollTo(0, 0);

  const { publishedNews } = useIdContext();
  const { location } = useGlobalLocation();
  // console.log("News", category, id, location);

  function artFilterFunc(item, cat) {
    let arr = item.filter((i) => {
      if (cat.length === 0) {
        return i;
      } else {
        return i.category === cat;
      }
    });
    return arr;
  }

  const setNewsBoxes = (n, newsState, classDiv, classBox) => {
    return (
      newsState && (
        <div className={classDiv}>
          {newsState.slice(n[0], n[1]).map((news, index) => {
            return <ArticalBox key={index} n={news} classes={displayClassName(classBox, n[0], index)} />;
          })}
        </div>
      )
    );
  };
  const displayClassName = (classes, n, index) => {
    if (window.innerWidth < 768 && n === 4 && index === 0) {
      return "boxWrapper mainBox box3 dark";
    } else {
      return classes;
    }
  };
  const setSubCategory = (item) => {
    if (location.pathname === "/vesti/") {
      return asideBoxesNews;
    } else {
      return asideBoxesNews2;
    }
  };

  function findTitleByRoute(route) {
    const found = options.find((item) => item.route === route);
    return found ? found.title : null;
  }
  return (
    <div className="news">
      <div className="news-wrapper">
        <h1>{findTitleByRoute(location.pathname)}</h1>

        <section className="home__box__1">
          <div className="home__box">
            <ArticalBox n={publishedNews[0]} classes="mainNews" />
          </div>
          {setNewsBoxes([1, 4], publishedNews, "home__rightBox", "boxWrapper box1")}
        </section>

        {setSubCategory().map((item, index) => {
          const getUrl = () => {
            if (item[2].length === 0) {
              return "";
            } else {
              return `${location.pathname + urlTitle2(item[2])}/`;
            }
          };
          return (
            <section key={index} className={item[3]}>
              {item[5] && (
                <h2>
                  <Link to={getUrl()}>{item[2]}</Link>
                </h2>
              )}
              <div className={item[8]}>{setNewsBoxes([item[0], item[1]], artFilterFunc(publishedNews, item[2]), `${item[4]}`, `${item[6]}`, `${item[7]}`)}</div>
            </section>
          );
        })}
      </div>
    </div>
  );
}
