import React, { useState } from "react";
import "./ArticleBox.scss";
import { Link, useNavigate } from "react-router-dom";
import PostImage from "../../containers/SinglePost/img/PostImage.js";
import { options } from "../../shared/shared.js";
import { urlTitle2 } from "../../shared/utillity.js";

export default function ArticalBox(props) {
  const [error, setError] = useState(false);
  const [fallBackSrc, setFallBackSrc] = useState("/img/fallBack.jpg");
  const history = useNavigate();

  const setPreUrlTitle = (item, allC, cat) => {
    const { route } = allC.find((e) => urlTitle2(e.title) === urlTitle2(item.category)) || {};
    return route ? `${route}${cat ? "" : urlTitle2(item.title) + "/"}` : "";
  };

  const articleData = props.n;

  const allCategories = options.reduce((acc, item) => {
    return acc.concat(item.item_list ? item.item_list : [item]);
  }, []);

  const imgSrc = !error ? articleData.pics[0] : fallBackSrc;
  const setTitle = articleData.title_2 ? articleData.title_2 : articleData.title;
  return (
    <div className={props.classes}>
      <div className="artBox-wrapper">
        <Link to={setPreUrlTitle(articleData, allCategories)} className="artBox-img">
          <PostImage src={imgSrc} alt={articleData.tags[0]} />
        </Link>
        <div className="artBox-desc">
          <Link to={setPreUrlTitle(articleData, allCategories)}>
            <h3>{setTitle}</h3>
          </Link>
          <div className="artBox-desc-other">
            <Link to={setPreUrlTitle(articleData, allCategories, true)}>{articleData.category}</Link>
          </div>
          <span className="artBox-lead">{articleData.lead}...</span>
          <button
            classes="artBox-btn"
            onClick={() => {
              history(setPreUrlTitle(articleData, allCategories));
            }}
          >
            Nastavi čitanje
          </button>
        </div>
      </div>
    </div>
  );
}
