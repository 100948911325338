import React from "react";

const setTime = (postTime, classes) => {
  let months = ["januar", "februar", "mart", "april", "maj", "jun", "jul", "avgust", "septembar", "oktobar", "novembar", "decembar"];
  let postDate = new Date(postTime);
  let date = postDate.getDate();
  let month = months[postDate.getMonth()];
  let year = postDate.getFullYear();

  let formattedDate = `${date}. ${month}`;

  if (classes !== "noDay") {
    formattedDate += ` ${year}.`;
  }
  return <>{formattedDate}</>;
};

export default function TimeFormat(props) {
  return <>{setTime(props.timePost, props.classes)}</>;
}
