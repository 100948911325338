import React, { lazy, Suspense } from "react";
import "./Home.scss";
import ArticalBox from "../../components/ArticalBox/ArticalBox.js";
import { Link } from "react-router-dom";
// import AdManagerSlot from "../../components/AdvModule/AdManagerSlot";
import { useGlobalLocation } from "../../shared/LocationContext.js";
import { useIdContext } from "../../shared/IdProvider.js";

const ArticleBoxLazy = lazy(() => import("../../components/ArticalBox/ArticalBox.js"));
const AdManagerSlotLazy = lazy(() => import("../../components/AdvModule/AdManagerSlot.js"));

export default function Home() {
  window.scrollTo(0, 0);
  const location = useGlobalLocation();
  const { promoNews, actualBoxNews, topNews, modifiedNews, produktnaBerza } = useIdContext();
  const boxes = [setNewsBoxes([1, 4], promoNews, "home__rightBox", "boxWrapper sideBox newsRightBox"), setNewsBoxes([4, 7], promoNews, "home__leftBox", displayClassName()), setNewsBoxes([0, 3], actualBoxNews, "box3__left", "boxWrapper box3"), setNewsBoxes([7, 11], promoNews, "home__box__4-layout", "boxWrapper mainBox bigBox"), setNewsBoxes([11, 15], promoNews, "home__box__4-layout", "boxWrapper box4"), setNewsBoxes([1, 4], produktnaBerza, "box3__left", "boxWrapper box3 produktna"), setNewsBoxes([0, 2], modifiedNews, "home__box__4-layout", "boxWrapper mainBox bigBox"), setNewsBoxes([2, 6], modifiedNews, "home__box__4-layout", "boxWrapper box4")];
  // console.log("Boxes", boxes[0]);
  function setNewsBoxes(n, newsState, classDiv, classBox) {
    if (!newsState) {
      return <div>Loading...</div>;
    }
    return (
      <div className={classDiv}>
        {newsState.slice(n[0], n[1]).map((news, index) => (
          <Suspense fallback={<div key={index}></div>} key={index}>
            <ArticleBoxLazy n={news} classes={classBox} />
          </Suspense>
        ))}
      </div>
    );
  }
  
  function displayClassName() {
    if (window.innerWidth > 500) {
      return "boxWrapper box2";
    } else {
      return "boxWrapper mainBox bigBox";
    }
  }
  return (
    <div className="home__1">
      <div className="banner-wrapper">
        <Suspense fallback={<div></div>}>
          <AdManagerSlotLazy adUnitPath={location.pathname} slotNumber={"div-gpt-ad-1710624971857-0"} />
        </Suspense>
      </div>
      <section className="home__box__1">
        <div className="home__box">
          <ArticalBox n={promoNews[0]} classes="mainNews category newsRightBox" />
        </div>
        <div className="home__rightBox">
          {promoNews.slice(1, 4).map((news, index) => {
            return <ArticalBox key={index} n={news} classes="boxWrapper sideBox newsRightBox" />;
          })}
        </div>
      </section>
      <section className="home__box__2">
        <div className="banner-wrapper">
          <Suspense fallback={<div></div>}>
            <AdManagerSlotLazy adUnitPath={location.pathname} slotNumber={"div-gpt-ad-1725692425016-0"} />
          </Suspense>
        </div>
        {boxes[1]}
        <div className="home__rightBox">
          <div className="banner-wrapper">
            <Suspense fallback={<div></div>}>
              <AdManagerSlotLazy adUnitPath={location.pathname} slotNumber={"div-gpt-ad-1711058573014-0"} />
            </Suspense>
          </div>
        </div>
      </section>
      <section className="topNews">
        {topNews && (
          <Suspense fallback={<div></div>}>
            <ArticleBoxLazy n={topNews[0]} classes="boxWrapper topNews" />
          </Suspense>
        )}
      </section>
      <section className="home__box__3">
        <div className="home_3_title">
          <span>Aktuelno</span>
        </div>
        <div className="box3">{boxes[2]}</div>
      </section>
      <div className="banner-wrapper">
        <Suspense fallback={<div></div>}>
          <AdManagerSlotLazy adUnitPath={location.pathname} slotNumber={"div-gpt-ad-1711282241522-0"} />
        </Suspense>
      </div>
      <section className="home__box__4">
        <span>Izdvojeno</span>
        {boxes[6]}
        {boxes[7]}
      </section>
      <section className="topNews">
        {topNews && topNews.length > 1 ? (
          <Suspense fallback={<div></div>}>
            <ArticleBoxLazy n={topNews[1]} classes="boxWrapper topNews" />
          </Suspense>
        ) : null}
      </section>
      <section className="home__box__3 produktna">
        <div className="home_3_title">
          <h2>
            <Link to="/vesti/produktna-berza/">Produktna berza</Link>
          </h2>
        </div>
        <div className="box3">{boxes[5]}</div>
      </section>
      <div className="banner-wrapper">
        <Suspense fallback={<div></div>}>
          <AdManagerSlotLazy adUnitPath={location.pathname} slotNumber={"div-gpt-ad-1712869897800-0"} />
        </Suspense>
      </div>
      <section className="home__box__4">
        <span>Pročitajte još...</span>
        {boxes[3]}
        {boxes[4]}
      </section>
      <div className="banner-wrapper">
        <Suspense fallback={<div></div>}>
          <AdManagerSlotLazy adUnitPath={location.pathname} slotNumber={"div-gpt-ad-1712870230327-0"} />
        </Suspense>
      </div>
    </div>
  );
}
