import React from "react";
import Helmet from "react-helmet";
import { options } from "../../shared/shared.js";
import { urlTitle2 } from "../../shared/utillity.js";
import { useGlobalLocation } from "../../shared/LocationContext.js";
// import { useIdContext } from "../../shared/IdProvider";
import produktnaBerza from "../../produktnaBerza.json";
import news from "../../all__news.json";
import { useImageSize } from "react-image-size";

export default function HeadHelmet() {
  // console.log("Helmet");
  const { location, pathPart, previousLocation } = useGlobalLocation();
  // const { post } = useIdContext();
  // const [isInternalReferrer, setIsInternalReferrer] = useState(false);
  // console.log("PrevUrl 1", isInternalReferrer);
  const currentDomain = window.location.hostname;
  const isInternalReferrer = previousLocation === currentDomain;
  // console.log("PAth part", pathPart);
  let allNews = produktnaBerza.concat(news).sort((a, b) => {
    return Date.parse(b.time2) - Date.parse(a.time2);
  });
  let lastPathPart = pathPart[pathPart.length - 2];
  // console.log("LastPathPart", lastPathPart);
  const post = setArticleState();
  const [dimensions, { loading, error }] = useImageSize(`${post.pics[0]}`);
  // console.log("Dimensions", dimensions?.width);
  // console.log("Window location", window.location.href.split("/"));
  // console.log(
  //   "Tags arr",
  //   post.tags.map((item) => [urlTitle2(item)])
  // );
  window.googletag.cmd.push(function () {
    // window.googletag.pubads().setTargeting("category", [`${post.category || post.category !== undefined ? urlTitle2(post.category) : ""}`]);
    window.googletag.pubads().setTargeting("article_id", [`${post.news_id || post.news_id !== undefined ? post.news_id : ""}`]);
    window.googletag.pubads().setTargeting("resolution", [`${window.innerWidth ? window.innerWidth : ""}`]);
    window.googletag.pubads().setTargeting("tags", `${post.tags || post.tags !== undefined ? post.tags.map((item) => [urlTitle2(item)]) : ""}`);
    // window.googletag.pubads().setTargeting("author", [`${post.source || post.source !== undefined ? post.source : ""}`]);
  });
  // console.log("PrevUrl 2", isInternalReferrer);

  function setArticleState() {
    // console.log("PrevUrl 3", isInternalReferrer);
    const validPathParts = ["vesti", "najnovije-vesti", "zivot-na-selu"];
    let categoryList0 = options.map((item) => {
      return item;
    });
    let categoryList = options[1].item_list.map((item) => {
      return item;
    });
    let allCategory = categoryList0.concat(categoryList).concat(options[6].item_list[0]).concat(options[7].item_list[0]);
    // console.log("AllCategory", allCategory);
    let allCategoryList = allCategory.map((item) => urlTitle2(item.title));
    // console.log("Category list", allCategoryList);
    // console.log("All Category list", allCategory);
    if (pathPart[3] && pathPart.length >= 4) {
      //for all posts
      // console.log("Hamlet IF 1");
      let post = allNews.filter((post) => {
        return urlTitle2(post.title) === pathPart[3];
      });
      window.googletag.cmd.push(function () {
        window.googletag.pubads().setTargeting("category", [`${urlTitle2(post[0].category)}`]);
      });
      // console.log("POST", post[0]);
      return post[0];
    } else if ((lastPathPart === "" && pathPart.length < 3) || validPathParts.includes(lastPathPart)) {
      //home page, vesti, najnovije
      // console.log("Hamlet IF 2");
      window.googletag.cmd.push(function () {
        window.googletag.pubads().setTargeting("category", [lastPathPart === "" ? "home" : lastPathPart]);
      });
      return options[0].social;
    } else if (allCategoryList.includes(lastPathPart)) {
      //produktna-berza, manifestacije, subvencije
      // console.log("Hamlet IF 3");
      let catNews = allNews.filter((item) => {
        return urlTitle2(item.category) === lastPathPart && item.published;
      });
      let catHelmetText = allCategory[allCategoryList.indexOf(lastPathPart)].social;
      let testSocTag = {
        post: catNews[0],
        pics: catNews[0].pics,
        source: "Agroweb.rs",
        title: catHelmetText.title,
        title_2: catHelmetText.title,
        time2: catNews[catNews.length - 1].time2,
        modified: catNews[0].time2,
        lead: catNews[0].title,
      };
      window.googletag.cmd.push(function () {
        window.googletag.pubads().setTargeting("category", [`${urlTitle2(catNews[0].category)}`]);
      });
      return testSocTag;
    } else if (pathPart[1] === "tag") {
      // for tags
      // console.log("Hamlet IF 4");
      //ispraviti
      let tagNews = allNews.filter((item) => {
        //array with formated tags
        let newTags = item.tags.map((item) => {
          return urlTitle2(item);
        });
        //filter only news with defined tag
        return newTags.includes(pathPart[2]);
      });
      //find original of tag name from pathPart[2]
      let tagValue = tagNews[0].tags.filter((item) => {
        return urlTitle2(item) === pathPart[2];
      });
      let socTag = tagValue[0].charAt(0).toUpperCase() + tagValue[0].slice(1) + " - Poljoprivredni portal Agroweb.rs";
      let testSocTag = {
        post: tagValue[0],
        pics: tagNews[0].pics,
        source: "Agroweb.rs",
        title_2: socTag,
        time2: tagNews[tagNews.length - 1].time2,
        modified: tagNews[0].time2,
        lead: tagNews[0].title,
      };
      return testSocTag;
    } else {
      // console.log("Hamlet IF 5");
      return options[0].social;
    }
  }
  const setSource = (item) => {
    return Array.isArray(item) ? `${item[1]}` : "agroweb.rs";
  };
  return (
    <Helmet>
      <title>{post.title_2 || post.title}</title>
      <meta name="description" content={post.lead} />
      <link rel="canonical" href={"https://agroweb.rs" + location.pathname} />
      {/* Opengraph - Facebook */}
      <meta name="title" content={post.title_2 || post.title} />
      <meta property="og:title" content={post.title_2 || post.title} />
      <meta property="og:description" content={post.lead} />
      <meta property="og:image:url" content={post.pics[0]} />
      <meta property="og:image" content={post.pics[0]} />
      <meta property="og:image:width" content={dimensions?.width} />
      <meta property="og:image:height" content={dimensions?.height} />
      <meta property="og:image:alt" content={post.title_2 || post.title} />
      {/* <meta property="og:image:type" content="image/jpeg" /> */}
      <meta name="author" content={setSource(post.source)} />
      <meta property="article:publisher" content="https://www.facebook.com/agroweb.portal" />
      <meta property="og:site_name" content="Agroweb.rs" />
      <meta property="article:published_time" content={post.time2} />
      {post.modified && <meta property="article:modified_time" content={post.modified} />}
      <meta property="og:url" content={"https://agroweb.rs" + location.pathname} />
      <meta name="twitter:url" content={"https://agroweb.rs" + location.pathname} />
      <meta name="twitter:title" content={post.title_2 || post.title} />
      <meta name="twitter:description" content={post.lead} />
      <meta name="twitter:image" content={post.pics[0]} />
      {/* End Opengraph */}
      <link rel="icon" href="/img/agroweb-favicon.png" type="image/x-icon" />
    </Helmet>
  );
}
