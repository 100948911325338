import React, { Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import "./SinglePost.scss";
import TimeFormat from "../../components/TimeFormat/TimeFormat.js";
import { asideBoxes } from "../../shared/shared.js";
import BodyText from "../../components/BodyText/BodyText.js";
import AdManagerSlot from "../../components/AdvModule/AdManagerSlot.js";
import { id2, setSource, urlTitle2 } from "../../shared/utillity.js";
import StickyAd from "../../components/AdvModule/StickyAd.js";
import { useGlobalLocation } from "../../shared/LocationContext.js";
import { useIdContext } from "../../shared/IdProvider.js";
import { useImageSize } from "react-image-size";

const SocialButtons = lazy(() => import("../../UI/SocialButtons/SocialButtons.js"));
const AsideBox = lazy(() => import("../../components/AsideBox/AsideBox.js"));

export default function SinglePost() {
  window.scrollTo(0, 0);
  const { location } = useGlobalLocation();
  const { category, post } = useIdContext();
  const [dimensions, { loading, error }] = useImageSize(`${post.pics[0]}`);
  return (
    <div className={`singlePost ${id2(category)}`}>
      <div className="banner-wrapper bilbord">
        <AdManagerSlot slotNumber={"div-gpt-ad-1710624971857-0"} />
      </div>
      <div className="content">
        <div className="content-wrapper">
          <main className="mainContent">
            <article className="mainContent-wrapper">
              <span className="mainContent-cat">{post.category}</span>
              <h1 className="mainContent-title">{post.title_2 ? post.title_2 : post.title}</h1>
              <p className="mainContent-lead">{post.lead}</p>
              <div className="time">
                {setSource(post.source)}, Datum: <TimeFormat timePost={post.modified ? post.modified : post.time2} />
              </div>
              <figure className="mainContent-img">
                <img src={`${post.pics[0]}`} alt={post.tags[0]} width={dimensions?.width} height={dimensions?.height} />
                <figcaption className="mainContent-img_source">
                  <span>Foto: </span>
                  <span dangerouslySetInnerHTML={{ __html: post.pics[1] }}></span>
                </figcaption>
              </figure>
              <div className="mainContent-social">
                <Suspense fallback={<div></div>}>
                  <SocialButtons testImg={post.pics[0]} location={location.pathname} postTitle={post.title_2 ? post.title_2 : post.title} />
                </Suspense>
              </div>
              <div className="mainContent-bodyText">
                <BodyText adUnitPath={location.pathname} bodyText={post.body} />
              </div>
              <div className="tags">
                <span className="tags-title">Tagovi</span>
                <div className="tags__arr">
                  {post.tags.map((item, index) => {
                    return (
                      <Link key={index} to={`/tag/${urlTitle2(item)}/`}>
                        {item}
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="banner-wrapper">
                <AdManagerSlot slotNumber={"div-gpt-ad-1711317911230-0"} />
              </div>
            </article>
            <Suspense fallback={<div>Loading...</div>}>
              <aside className="newsAside foot">
                <AsideBox items={[...asideBoxes[0]]} classes="newsAside foot" dispAds={false} adUnitPath={location.pathname} />
                <AsideBox items={[...asideBoxes[1]]} classes="newsAside foot" dispAds={false} adUnitPath={location.pathname} />
              </aside>
            </Suspense>
          </main>
          <Suspense fallback={<div>Loading...</div>}>
            <aside className="newsAside">
              <AsideBox items={[...asideBoxes[2]]} classes="newsAside" dispAds={true} adUnitPath={location.pathname} />
              <div className="banner-wrapper">
                <AdManagerSlot slotNumber={"div-gpt-ad-1711058573014-0"} />
              </div>
              <AsideBox items={[...asideBoxes[3]]} classes="newsAside" dispAds={true} adUnitPath={location.pathname} />
            </aside>
          </Suspense>
        </div>
        <div className="banner-wrapper">
          <AdManagerSlot slotNumber={"div-gpt-ad-1711282241522-0"} />
        </div>
        <StickyAd adUnitPath={location.pathname} />
      </div>
    </div>
  );
}
