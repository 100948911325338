import React, { Suspense, lazy } from "react";
import "./AsideBox.scss";
import { useIdContext } from "../../shared/IdProvider.js";

const NewsBox = lazy(() => import("../../components/NewsBox/NewsBox.js"));

export default function AsideBox(props) {
  const { filtered1, filterCategory, page_view, post, modifiedNews, articlesInText } = useIdContext();
  const arrGroups = [filterCategory, modifiedNews, filtered1, page_view, articlesInText];
  // console.log("ARR", props.items[0], arrGroups[props.items[0]]);
  return arrGroups[props.items[0]].length > 1 ? (
    <section className={props.items[6]}>
      <div className="newsAside-box-title">
        <h2>{props.items[5] ? props.items[2] + post.category : props.items[2]}</h2>
      </div>
      <Suspense fallback={<div></div>}>
        <NewsBox val={[[...arrGroups[props.items[0]]], ...props.items]} />
      </Suspense>
    </section>
  ) : null;
}
